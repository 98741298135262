import React from 'react';
import GalleryPageContent from '../../components/gallery/GalleryPageContent';

const Gallery = () => {
    return (
        <>
            <div className="page-wrapper">
                <span className="header-span"></span>
                <GalleryPageContent />
            </div>
        </>
    );
};

export default Gallery;